exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-blog-js": () => import("./../../../src/pages/blog.js" /* webpackChunkName: "component---src-pages-blog-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-policy-js": () => import("./../../../src/pages/policy.js" /* webpackChunkName: "component---src-pages-policy-js" */),
  "component---src-pages-preview-js": () => import("./../../../src/pages/preview.js" /* webpackChunkName: "component---src-pages-preview-js" */),
  "component---src-pages-terms-js": () => import("./../../../src/pages/terms.js" /* webpackChunkName: "component---src-pages-terms-js" */),
  "component---src-templates-category-app-js": () => import("./../../../src/templates/categoryApp.js" /* webpackChunkName: "component---src-templates-category-app-js" */),
  "component---src-templates-category-js": () => import("./../../../src/templates/category.js" /* webpackChunkName: "component---src-templates-category-js" */),
  "component---src-templates-help-js": () => import("./../../../src/templates/help.js" /* webpackChunkName: "component---src-templates-help-js" */),
  "component---src-templates-landing-page-contents-js": () => import("./../../../src/templates/landingPage/contents.js" /* webpackChunkName: "component---src-templates-landing-page-contents-js" */),
  "component---src-templates-landing-page-online-selling-js": () => import("./../../../src/templates/landingPage/onlineSelling.js" /* webpackChunkName: "component---src-templates-landing-page-online-selling-js" */),
  "component---src-templates-post-app-js": () => import("./../../../src/templates/postApp.js" /* webpackChunkName: "component---src-templates-post-app-js" */),
  "component---src-templates-post-blog-js": () => import("./../../../src/templates/postBlog.js" /* webpackChunkName: "component---src-templates-post-blog-js" */)
}

